import { createSlice } from '@reduxjs/toolkit'

const {
  getAllEbmData,
  repushEbmThatHasError,
  findGeneratedEbmInvoice,
} = require('src/actions/uob/uob-action')

const initEbm = {
  invoices: [],
  success: false,
  error: false,
  loading: false,
  message: '',
  postedData: {},
  ebmInvoice: {},
}
const EbmSlice = createSlice({
  name: 'ebm',
  initialState: initEbm,
  reducers: {
    initData: (state) => {
      return { ...state, invoices: [], success: false, error: false, message: '', loading: false }
    },
    showJsonDataOnItem: (state, action) => {
      return { ...state, postedData: action?.payload }
    },
  },
  extraReducers: (b) => {
    b.addCase(findGeneratedEbmInvoice.pending, (state) => {
      state.loading = true
      state.success = false
      state.error = false
    })
      .addCase(findGeneratedEbmInvoice.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.error = false
        state.message = action?.message
        state.ebmInvoice = action?.payload?.data
      })
      .addCase(findGeneratedEbmInvoice.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload.message
      })
      .addCase(getAllEbmData.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(getAllEbmData.fulfilled, (state, action) => {
        state.loading = false
        state.success = false
        state.error = false
        state.message = 'Success'
        state.invoices = action?.payload
      })
      .addCase(getAllEbmData.rejected, (state) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = 'System Error Found'
        state.invoices = []
      })
      .addCase(repushEbmThatHasError.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(repushEbmThatHasError.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.error = false
        state.message = action?.payload?.message
      })
      .addCase(repushEbmThatHasError.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
  },
})
export const { initData, showJsonDataOnItem } = EbmSlice.actions
export default EbmSlice.reducer
