import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationManager } from 'react-notifications'
import apiCall from 'src/backend/http'

export const getMotoPrivateInsuranceContract = createAsyncThunk(
  'insurance/bk-tech/insuranceContract',
  async (newRiskData, { rejectWithValue }) => {
    try {
      const { data, status } = await apiCall.post(
        'insurance/bk-tech/insuranceContract',
        newRiskData,
      )
      if (status === 200) {
        NotificationManager.success('Insurance Policy Has been to email and sms successfully!')
        return data
      } else {
        NotificationManager.error('Failed to update new risk information')
        return rejectWithValue('Failed to update new risk information')
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message
      NotificationManager.error(errorMessage)
      return rejectWithValue(errorMessage)
    }
  },
)

export const getInsuranceContract = createAsyncThunk(
  'insurance/bk-tech/getInsuranceContract',
  async (quotationNo, { rejectWithValue }) => {
    try {
      const { data, status } = await apiCall.get(
        `insurance/bk-tech/get_contract_status?docNo=${quotationNo}`,
      )
      if (status === 200) {
        NotificationManager.success('Contract details fetched successfully!')
        return data
      } else {
        NotificationManager.error('Failed to fetch contract details.')
        return rejectWithValue('Failed to fetch contract details.')
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message
      NotificationManager.error(errorMessage)
      return rejectWithValue(errorMessage)
    }
  },
)
