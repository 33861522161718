import axios from 'axios'
const url = process.env.REACT_APP_BASE_URL
const url1 = process.env.REACT_APP_BASE_URL1
const env = process.env.REACT_APP_ENV
const apiCall = axios.create({
  baseURL: url,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    PARTNER: 'BKGI',
  },
})
const apiCall2 = axios.create({
  baseURL: url1,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    PARTNER: 'BKGI',
  },
})
apiCall2.interceptors.request.use(
  (config) => {
    const token = tokenUser
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      config.headers.Authorization = 'BKGI'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
apiCall.interceptors.request.use(
  (config) => {
    const token = tokenUser
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      config.headers.Authorization = 'BKGI'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

apiCall.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

apiCall2.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)
const handleStateUpdate = (
  state,
  { loading = false, success = false, error = false, message = null },
) => {
  state.loading = loading
  state.success = success
  state.error = error
  state.message = message
}
const handleSuccessStateUpdate = (state, action) => {
  state.token = action?.payload?.data?.token
  state.user = action?.payload?.data?.user
  handleStateUpdate(state, {
    success: true,
    message: action?.payload?.message,
  })
}

const handleErrorStateUpdate = (state, action) => {
  handleStateUpdate(state, {
    error: true,
    message: action?.payload?.response?.data?.message,
  })
}

const handleLoadingStateUpdate = (state) => {
  handleStateUpdate(state, { loading: true, message: 'Loading Please Wait ........' })
}
const tokenUser = localStorage.getItem('token')
const hasToken = tokenUser !== null && tokenUser !== undefined
export {
  handleSuccessStateUpdate,
  hasToken,
  handleErrorStateUpdate,
  handleLoadingStateUpdate,
  apiCall2,
}

export default apiCall
