import { createChatBotMessage } from 'react-chatbot-kit'
import BotAvatar from './components/BotAvatar/BotAvatar'
import React from 'react'

const config = {
  initialMessages: [createChatBotMessage(`Hi There how May i Help You ❤️`)],
  botName: 'BKGI',
  customComponents: {
    botAvatar: (props) => <BotAvatar {...props} />,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: '#012e5c',
    },
    chatButton: {
      backgroundColor: '#012e5c',
    },
  },
}

export default config
