import { createSlice } from '@reduxjs/toolkit'
import {
  allProducts,
  ChooseInsuranceProduct,
  allCoversByProduct,
  allVehicleTypes,
  allCoverPeriodByProduct,
  samInsuredPerOccupants,
  allRisqueClassifications,
  productDiscountRates,
} from 'src/actions/products/product-action'
const initialState = {
  loading: false,
  success: false,
  covers: [],
  coverPeriods: [],
  occupants: [],
  motoTypes: [],
  error: false,
  discounts: [],
  message: '',
  risques: [],
  selectProduct: {},
  products: [],
}

const ProductSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    initializeProduct: (state) => {
      return { ...state, loading: false, success: false, error: false, message: '' }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allProducts.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(allProducts.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.error = false
        state.message = action?.payload?.message
        state.products = action?.payload?.data
      })
      .addCase(allProducts.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
      // start handling covers
      .addCase(allCoversByProduct.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(allCoversByProduct.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          covers: action?.payload?.data,
          message: action?.payload?.message,
        }
      })
      .addCase(allCoversByProduct.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
      // end from here
      // start moto types
      .addCase(allVehicleTypes.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(allVehicleTypes.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          motoTypes: action?.payload?.data,
          message: action?.payload?.message,
        }
      })
      .addCase(allVehicleTypes.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
      // end of moto types

      //start for the cover period
      .addCase(allCoverPeriodByProduct.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(allCoverPeriodByProduct.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          coverPeriods: action?.payload?.data,
          message: action?.payload?.message,
        }
      })
      .addCase(allCoverPeriodByProduct.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
      // end of the cover period

      //start for the samInsuredPerOccupants
      .addCase(samInsuredPerOccupants.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(samInsuredPerOccupants.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          occupants: action?.payload?.data,
          message: action?.payload?.message,
        }
      })
      .addCase(samInsuredPerOccupants.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
      // end of samInsuredPerOccupants
      .addCase(allRisqueClassifications.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(allRisqueClassifications.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          risques: action?.payload?.data,
          message: action?.payload?.message,
        }
      })
      .addCase(allRisqueClassifications.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
      // update discount status
      .addCase(productDiscountRates.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(productDiscountRates.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          discounts: action?.payload?.data,
          message: action?.payload?.message,
        }
      })
      .addCase(productDiscountRates.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
      // end of configuring discount status
      .addCase(ChooseInsuranceProduct, (state, action) => {
        return {
          ...state,
          selectProduct: action.payload,
          covers: [],
          motoTypes: [],
          risques: [],
          coverPeriods: [],
          occupants: [],
          loading: false,
          discounts: [],
          success: false,
        }
      })
  },
})
export { allProducts, initialState, ChooseInsuranceProduct }
export const { initializeProduct } = ProductSlice.actions
export default ProductSlice.reducer
