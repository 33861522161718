import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  countdown: 25,
  status: 'idle',
  retries: 0,
}
const retrialSlice = createSlice({
  name: 'retrial',
  initialState,
  reducers: {
    retrialMechanism(state, action) {
      if (action.payload === 'stop') {
        state.retries = 0
        state.countdown = 25
        state.status = 'idle'
      } else {
        if (state.countdown > 0) {
          state.countdown -= 1
          state.status = 'checking'
        }
        if (state.countdown == 0) {
          state.retries += 1
          state.countdown = 25
        }
        if (state.retries > 1) {
          state.retries = 0
          state.countdown = 25
          state.status = 'idle'
        }
      }
    },
  },
})

export const { retrialMechanism } = retrialSlice.actions

export default retrialSlice.reducer
