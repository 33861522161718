import { createSlice } from '@reduxjs/toolkit'

import {
  GetBeneficialOwnerReport,
  GetNidaUserInfo,
  createNewBeneficialOwnerAction,
} from 'src/actions/uob/uob-action'
const initialState = {
  uobRequest: {
    clientInfo: {},
    shareHolder: [],
    finalUob: [],
    isShareHolderAdded: false,
  },
  loading: false,
  success: false,
  error: false,
  message: '',
  nidaData: null,
}

const uobSlice = createSlice({
  name: 'uob',
  initialState,
  extraReducers: (exRed) => {
    exRed
      .addCase(createNewBeneficialOwnerAction.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(createNewBeneficialOwnerAction.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          message: action.payload?.message,
        }
      })
      .addCase(createNewBeneficialOwnerAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(GetBeneficialOwnerReport.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(GetBeneficialOwnerReport.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          uobReport: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(GetBeneficialOwnerReport.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(GetNidaUserInfo.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(GetNidaUserInfo.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          nidaData: action.payload.data,
          message: action.payload?.message,
        }
      })
      .addCase(GetNidaUserInfo.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
  },
  reducers: {
    updateClientInfo: (state, action) => {
      state.uobRequest.clientInfo = action.payload
    },
    updateShareHolderInfo: (state, action) => {
      state.uobRequest.shareHolder = action.payload
      state.uobRequest.finalUob = action.payload
    },
    addedUob: (state, action) => {
      const newUob = action.payload

      state.uobRequest.finalUob = state.uobRequest.finalUob.map((holder) => {
        if (holder.shareHolderId === newUob.tinNo) {
          return {
            ...holder,
            uob: newUob,
          }
        }
        return holder
      })

      state.uobRequest.shareHolder = state.uobRequest.shareHolder.filter(
        (holder) => holder.shareHolderId !== newUob.tinNo,
      )
    },
    clearUobInfo: (state) => {
      state.uobRequest.finalUob = []
      state.uobRequest.isShareHolderAdded = false
    },
  },
})

export const { updateClientInfo, updateShareHolderInfo, addedUob, clearUobInfo } = uobSlice.actions
export default uobSlice.reducer
