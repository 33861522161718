import { createSlice } from '@reduxjs/toolkit'
import { allVehicleTypes } from 'src/actions/products/product-action'
import { vehicleCathegoris } from 'src/backend/utility'

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    motoTypes: [],
    uniqueCategories: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allVehicleTypes.fulfilled, (state, action) => {
      state.motoTypes = action.payload
      state.uniqueCategories = vehicleCathegoris(action.payload)
    })
  },
})

export default vehicleSlice.reducer
