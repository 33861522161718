import { createSlice } from '@reduxjs/toolkit'
import {
  COMESA_COVER_SELECTED,
  COVER_SELECTED,
  SELECTED_MOTO_TYPE,
  keepItem,
  restSubmitionStatus,
  sendQuotationToEmail,
  submitFireQuotation,
  submitMotoQuotationAction,
  submitNoMotoQuotation,
  submitPersonalAccidentQuotation,
  updatedCovers,
} from 'src/actions/quotation/quotation-action'
import { GetQuotationReport } from 'src/actions/uob/uob-action'

const initialState = {
  loading: false,
  success: false,
  error: false,
  message: '',
  quoResp: {},
  request: {},
  choosedCover: {},
  comesaCover: {},
  updatedCovers: {},
  quotationRegistor: [],
  choosedMotTypeItem: {},
}
const CalculateQuotationSlice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {
    initializeQuotion: (state) => {
      return { ...state, loading: false, success: false, error: false, message: '' }
    },
  },
  extraReducers: (d) => {
    d.addCase(SELECTED_MOTO_TYPE, (state, action) => {
      return { ...state, choosedMotTypeItem: action.payload }
    })
    d.addCase(submitFireQuotation.pending, (state) => {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      }
    })
      .addCase(submitFireQuotation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          quoResp: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(submitFireQuotation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(GetQuotationReport.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(GetQuotationReport.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          quotationRegistor: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(GetQuotationReport.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      //sendQuotation to email
      .addCase(sendQuotationToEmail.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(sendQuotationToEmail.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          message: action.payload?.message,
        }
      })
      .addCase(sendQuotationToEmail.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      //non moto
      .addCase(submitNoMotoQuotation.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(submitNoMotoQuotation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          quoResp: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(submitNoMotoQuotation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      //end of submiting fire
      .addCase(submitMotoQuotationAction.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(submitMotoQuotationAction.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          quoResp: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(submitMotoQuotationAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(submitPersonalAccidentQuotation.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(submitPersonalAccidentQuotation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          quoResp: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(submitPersonalAccidentQuotation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(restSubmitionStatus, (state) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: false,
          message: '',
        }
      })
      .addCase(keepItem, (state, action) => {
        return { ...state, request: action.payload }
      })
      .addCase(COVER_SELECTED, (state, action) => {
        return { ...state, choosedCover: action.payload }
      })
      .addCase(COMESA_COVER_SELECTED, (state, action) => {
        return { ...state, comesaCover: action.payload }
      })
      .addCase(updatedCovers, (state, action) => {
        return { ...state, updatedCovers: action.payload }
      })
  },
})
export const { initializeQuotion } = CalculateQuotationSlice.actions

export default CalculateQuotationSlice.reducer
