import apiCall from 'src/backend/http'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

const allProducts = createAsyncThunk('insurance/products', async (_, thunkAPI) => {
  try {
    const { data } = await apiCall.get('insurance/products')
    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

const ChooseInsuranceProduct = createAction('SELECT_PRODUCT')

const allCoversByProduct = createAsyncThunk('insurance/covers', async (_, toolKit) => {
  try {
    const {
      app: {
        bkProduct: { selectProduct },
      },
    } = toolKit.getState()
    const { data } = await apiCall.get(`insurance/covers?productId=${selectProduct?.product_ID}`)
    return data
  } catch (error) {
    return toolKit.rejectWithValue(error.response.data)
  }
})
const allCoverPeriodByProduct = createAsyncThunk(
  'insurance/covers-period',
  async (coverId, toolKit) => {
    try {
      const {
        app: {
          bkProduct: { selectProduct },
        },
      } = toolKit.getState()

      const url =
        selectProduct.product_CODE === 'FIRE_I'
          ? `insurance/covers-period-product?productId=${selectProduct?.product_ID}`
          : `insurance/covers-period?productId=${selectProduct?.product_ID}&&coverId=${coverId}`
      const { data } = await apiCall.get(url)
      return data
    } catch (error) {
      return toolKit.rejectWithValue(error.response.data)
    }
  },
)
const allVehicleTypes = createAsyncThunk('insurance/moto/types', async (_, toolKit) => {
  try {
    const { selectProduct } = toolKit.getState()?.app?.bkProduct
    const { data } = await apiCall.get(
      `insurance/moto/types?productId=${selectProduct?.product_ID}`,
    )
    return data
  } catch (error) {
    return toolKit.rejectWithValue(error.response.data)
  }
})

const samInsuredPerOccupants = createAsyncThunk('insurance/moto/occupants', async (_, toolKit) => {
  try {
    const { selectProduct } = toolKit.getState()?.app?.bkProduct
    const { data } = await apiCall.get(
      `insurance/moto/occupants?productId=${selectProduct?.product_ID}`,
    )
    return data
  } catch (error) {
    return toolKit.rejectWithValue(error.response.data)
  }
})

const allRisqueClassifications = createAsyncThunk('insurance/riskClass', async (_, toolKit) => {
  try {
    const { selectProduct } = toolKit.getState()?.app?.bkProduct
    const { data } = await apiCall.get(`insurance/riskClass?productId=${selectProduct?.product_ID}`)
    return data
  } catch (error) {
    return toolKit.rejectWithValue(error.response.data)
  }
})
const productDiscountRates = createAsyncThunk(
  'insurance/moto/discount-rate',
  async (_, toolKit) => {
    try {
      const { selectProduct } = toolKit.getState()?.app?.bkProduct
      const { data } = await apiCall.get(
        `insurance/moto/discount-rate?productId=${selectProduct?.product_ID}`,
      )
      return data
    } catch (error) {
      return toolKit.rejectWithValue(error.response.data)
    }
  },
)

export {
  allProducts,
  ChooseInsuranceProduct,
  allCoversByProduct,
  allRisqueClassifications,
  allVehicleTypes,
  allCoverPeriodByProduct,
  samInsuredPerOccupants,
  productDiscountRates,
}
