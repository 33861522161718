import React from 'react'

const BotAvatar = () => {
  return (
    <div className="bg-white/50 rounded-md">
      <svg width={40} height={40} viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="#012e5c"
          clipRule="evenodd"
          d="M27.1814 27.6926H21.5734V26.522C18.1786 26.1534 15.7801 23.9119 15.7801 20.9825C15.7801 18.0263 17.9277 15.4349 21.5734 15.0851V13.4832C17.4414 13.9015 14.8237 16.502 14.2714 19.6503V0.307617H27.1814V27.6926ZM10.9544 19.4047C10.4054 16.0974 7.79093 13.7666 3.27214 13.5751V0.307617H10.9544V19.4047ZM14.2714 22.1348C14.7533 24.8311 16.8639 26.9526 19.8431 27.6933H14.2714V22.1348ZM10.9542 22.014V27.6944H4.75482C8.16691 27.0736 10.4506 24.9415 10.9542 22.014ZM3.27214 15.1849C7.52413 15.1849 9.44327 17.5964 9.44327 20.8138C9.44327 24.0247 7.1905 26.4108 3.27214 26.4108V15.1849ZM0 28H27.5535V0H0V28Z"
          fill="#012e5c"
        />
      </svg>
    </div>
  )
}

export default BotAvatar
