import { createSlice } from '@reduxjs/toolkit'
import {
  createNewSystemUser,
  fetchAllSystemUsers,
  userAuthenticationAction,
  fetchAllUserRoles,
  assignUserRole,
  ChangePassword,
  forgotPassword,
  createUserRole,
} from 'src/actions/login/login-action'
import {
  handleErrorStateUpdate,
  handleSuccessStateUpdate,
  handleLoadingStateUpdate,
} from 'src/backend/http'
import { objToString } from 'src/backend/utility'

const initialState = {
  token: null,
  user: {},
  systemUsers: [],
  userRoles: [],
  assignRole: [],
  otp: false,
  loading: false,
  success: false,
  error: false,
  message: '',
}

const userManagementSlice = createSlice({
  name: 'userMngmt',
  initialState,
  reducers: {
    initializeSystemUser: (state) => {
      return { ...state, loading: false, success: false, error: false, message: '' }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userAuthenticationAction.pending, (state) => {
        return { ...state, loading: true, success: false, error: false, message: '' }
      })
      .addCase(userAuthenticationAction.fulfilled, (state, action) => {
        return {
          ...state,
          token: action?.payload?.data?.token,
          user: action?.payload?.data?.user,
          loading: false,
          success: true,
          error: false,
          message: action?.payload?.data.message,
        }
      })
      .addCase(userAuthenticationAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message:
            'Phone Number or username is not register to the system get one online service to be able to login',
        }
      })
      .addCase(createNewSystemUser.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(createNewSystemUser.fulfilled, (state, action) => {
        const newUser = action.payload?.data
        state.loading = false
        state.success = true
        state.error = false
        state.message = action.payload?.message
        state.systemUsers = state.systemUsers ? [...state.systemUsers, newUser] : [newUser]
      })
      .addCase(createNewSystemUser.rejected, (state, action) => {
        state.message = objToString(action?.payload?.message)
        state.loading = false
        state.success = false
        state.error = true
      })

      .addCase(createUserRole.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })

      .addCase(createUserRole.fulfilled, (state, action) => {
        const newRole = action.payload.data
        state.loading = false
        state.success = true
        state.error = false
        state.message = action.payload?.message
        state.userRoles = state.userRoles ? [...state.userRoles, newRole] : [newRole]
      })

      .addCase(createUserRole.rejected, (state, action) => {
        state.message = objToString(action?.payload?.message)
        state.loading = false
        state.success = false
        state.error = true
      })

      .addCase(fetchAllSystemUsers.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllSystemUsers.fulfilled, (state, action) => {
        state.systemUsers = Array.isArray(action.payload) ? action.payload : []
        state.loading = false
      })
      .addCase(fetchAllSystemUsers.rejected, (state, action) => {
        state.loading = false
        state.error = true
        state.message = objToString(action?.payload?.message)
      })
      .addCase(fetchAllUserRoles.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllUserRoles.fulfilled, (state, action) => {
        state.userRoles = Array.isArray(action.payload) ? action.payload : []
        state.loading = false
      })
      .addCase(fetchAllUserRoles.rejected, (state, action) => {
        state.loading = false
        state.error = true
        state.message = objToString(action?.payload?.message)
      })
      .addCase(assignUserRole.pending, (state) => {
        state.loading = true
      })
      .addCase(assignUserRole.fulfilled, (state, action) => {
        state.assignRole = Array.isArray(action.payload) ? action.payload : []
        state.loading = false
      })
      .addCase(assignUserRole.rejected, (state, action) => {
        state.loading = false
        state.error = true
        state.message = objToString(action?.payload?.message)
      })
      .addCase(ChangePassword.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: false,
          message: '',
          success: false,
        }
      })
      .addCase(ChangePassword.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          error: false,
          message: action.payload?.message,
          success: true,
        }
      })
      .addCase(ChangePassword.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: true,
          message: objToString(action.payload?.message),
          success: false,
        }
      })
      .addCase(forgotPassword.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: false,
          success: false,
        }
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        return {
          ...state,
          otp: true,
          loading: false,
          error: false,
          message: action?.payload?.message,
          success: true,
        }
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: true,
          message: action?.payload?.message,
          success: false,
        }
      })
  },
})

export const { initializeSystemUser } = userManagementSlice.actions
export default userManagementSlice.reducer
