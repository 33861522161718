import { createSlice } from '@reduxjs/toolkit'
import { getNidaInformation, refleshNidaState } from 'src/actions/quotation/quotation-action'
const initialState = {
  loading: false,
  success: false,
  error: false,
  message: '',
  info: {},
}

const NidaSliceInfo = createSlice({
  name: 'nidaInfo',
  initialState,
  reducers: {
    initializeNida: (state) => {
      return { ...state, loading: false, success: false, error: false }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNidaInformation.pending, (state) => {
        state.loading = true
        state.success = false
        state.error = false
      })
      .addCase(getNidaInformation.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.error = false
        state.message = action?.payload?.message
        state.info = action?.payload?.data
      })
      .addCase(getNidaInformation.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = true
        state.message = action?.payload?.message
      })
      .addCase(refleshNidaState, (state) => {
        return { ...state, loading: false, success: false, error: false, message: '', info: {} }
      })
  },
})
export const { initializeNida } = NidaSliceInfo.actions
export default NidaSliceInfo.reducer
