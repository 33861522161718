import { createAsyncThunk } from '@reduxjs/toolkit'
import apiCall from 'src/backend/http'
import { NotificationManager } from 'react-notifications'

const documentList = createAsyncThunk('documents/list', async (nationalId, thunkAPI) => {
  try {
    const { data } = await apiCall.get(`bkgi-api/files/${nationalId}`)
    NotificationManager.success(data?.message)
    return { nationalId, documents: data.data || [] } // Provide default empty array
  } catch (error) {
    console.error('API Error:', error.response?.data)
    const { message } = error.response?.data
    NotificationManager.error(message)
    return thunkAPI.rejectWithValue(error.response?.data)
  }
})

const viewDocument = createAsyncThunk(
  'documents/view',
  async ({ nationalId, fileName }, thunkAPI) => {
    try {
      const response = await apiCall.get(`bkgi-api/download/${nationalId}/${fileName}`, {
        responseType: 'blob',
      })
      return { blob: response.data, fileName }
    } catch (error) {
      console.error('View Document Error:', error.message)
      NotificationManager.error('Failed to view document')
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
const downloadDocument = createAsyncThunk(
  'documents/download',
  async ({ nationalId, fileName }, thunkAPI) => {
    try {
      const response = await apiCall.get(`bkgi-api/download/${nationalId}/${fileName}`, {
        responseType: 'blob',
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
      NotificationManager.success('Document downloaded successfully')
    } catch (error) {
      console.error('Download Document Error:', error.message)
      NotificationManager.error('Failed to download document')
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export { documentList, viewDocument, downloadDocument }
