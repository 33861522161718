import { createSlice } from '@reduxjs/toolkit'
import { documentList, viewDocument, downloadDocument } from 'src/actions/dms/dms-action'

const initialState = {
  documents: [],
  nationalId: null,
  success: false,
  loading: false,
  error: null,
  message: '',
  viewingDocument: null,
}

const DocumentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    clearViewingDocument: (state) => {
      state.viewingDocument = null
      state.error = null
    },
    setNationalId: (state, action) => {
      state.nationalId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(documentList.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(documentList.fulfilled, (state, action) => {
        state.loading = false
        state.documents = action.payload.documents || [] // Provide a default empty array
        state.nationalId = action.payload.nationalId // National ID is stored
        state.error = null
      })
      .addCase(documentList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(viewDocument.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(viewDocument.fulfilled, (state, action) => {
        state.loading = false
        state.viewingDocument = action.payload
        state.error = null
      })
      .addCase(viewDocument.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(downloadDocument.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(downloadDocument.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(downloadDocument.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export const { clearViewingDocument, setNationalId } = DocumentSlice.actions

export default DocumentSlice.reducer
