class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider
    this.state = state
  }

  parse(message) {
    console.log('User Message:', message)

    if (message.trim().length > 0) {
      this.actionProvider.handleHello()
    }
  }
}

export default MessageParser
