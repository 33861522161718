import apiCall from 'src/backend/http'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationManager } from 'react-notifications'

/** bk-auth/auth/authenticate */
const userAuthenticationAction = createAsyncThunk(
  'bk-auth/auth/authenticate',
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('bk-auth/auth/authenticate', requestData)
      localStorage.setItem('token', data?.data?.token)
      return data
    } catch (error) {
      NotificationManager.error(error?.response ? error?.response?.data?.message : error.message)
      return rejectWithValue(error.response ? error.response.data : { message: 'Network error' })
    }
  },
)

/** bk-auth/auth/creatUser */
const createNewSystemUser = createAsyncThunk(
  'bk-auth/auth/createUser',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('bk-auth/auth/createUser', postData)
      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : { message: 'Network error' })
    }
  },
)
/** bk-auth/auth/allSystemUser */
export const fetchAllSystemUsers = createAsyncThunk(
  'bk-auth/auth/allSystemUser',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await apiCall.get('/bk-auth/auth/allSystemUser', requestData)
      return response.data.data
    } catch (e) {
      return rejectWithValue(e.response ? e.response.data : { message: 'Network error' })
    }
  },
)

/** bk-auth/auth/createRole */
export const createUserRole = createAsyncThunk(
  'bk-auth/auth/createRole',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('bk-auth/auth/createRole', postData)
      return data
    } catch (e) {
      return rejectWithValue(e.response ? e.response.data : { message: 'Network Error' })
    }
  },
)

/** bk-auth/auth/fetchAllUserRoles */

export const fetchAllUserRoles = createAsyncThunk(
  'bk-auth/auth/allRoles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiCall.get('/bk-auth/auth/allRoles')

      return response.data.data
    } catch (e) {
      return rejectWithValue(e.response ? e.response.data : { message: 'Network error' })
    }
  },
)

export const assignUserRole = createAsyncThunk(
  'bk-auth/auth/assignUserToRole',
  async (requestData, { rejectWithValue }) => {
    try {
      const { username, roleName } = requestData

      const response = await apiCall.get(
        `/bk-auth/auth/assignUserToRole?username=${username}&roleName=${roleName}`,
      )

      return response.data.data
    } catch (e) {
      return rejectWithValue(e.response ? e.response.data : { message: 'Network error' })
    }
  },
)

/** bk-auth/auth/change-password */
export const ChangePassword = createAsyncThunk(
  'bk-auth/auth/change-password',
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('/bk-auth/auth/change-password', requestData)
      return data
    } catch (e) {
      console.error('Error Response:', e.response ? e.response.data : 'Network Error')
      return rejectWithValue(e.response ? e.response.data : { message: 'Network Error' })
    }
  },
)

/** bk-auth/auth/forget-password */

export const forgotPassword = createAsyncThunk(
  'bk-auth/auth/forget-password',
  async (requestData, { rejectWithValue }) => {
    try {
      const { username } = requestData

      const { data, status } = await apiCall.get(
        `/bk-auth/auth/forget-password?username=${username}`,
        requestData,
      )

      if (status == 200) {
        NotificationManager.success(data?.message || 'New Password Generated Successfully')
      } else {
        NotificationManager.warning(data?.message || 'Invalid username or phone number')
      }
      return data
    } catch (error) {
      NotificationManager.error(error.response ? error.response.data : error.message)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  },
)

export const LOGOUT = 'LOGOUT'

export { userAuthenticationAction, createNewSystemUser }
