import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userManagementReducer from './slices/user_management_slice'
import productReducer from './slices/productSlice'
import displayReducer from './slices/displaySlice'
import calculateQuotationReducer from './slices/quotationSlice'
import NidaInfo from './slices/nidaInfoSlice'
import uobReducer from './slices/uobSlice'
import buyInsuranceReducer from './slices/buyInsuranceSlice'
import vehicleReducer from './slices/vehicleSlice'
import EbmSlice from './slices/EbmSlice'
import DocumentSlice from './slices/document-management-slice'
import RetrialSlice from './slices/retrialSlice'
const rootReducer = combineReducers({
  userMngmt: userManagementReducer,
  bkProduct: productReducer,
  ui_display: displayReducer,
  nida: NidaInfo,
  quotation: calculateQuotationReducer,
  uob: uobReducer,
  newPolicy: buyInsuranceReducer,
  vehicle: vehicleReducer,
  retrialMechanism: RetrialSlice,
  ebm: EbmSlice,
  docsList: DocumentSlice,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const bkAppReducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
      state = undefined
      storage.removeItem('persist:root')
      localStorage.removeItem('token')
      break
    case 'persist/PERSIST':
    case 'persist/REHYDRATE':
    case '@@INIT':
      break
    default:
  }
  return persistedReducer(state, action)
}

export default bkAppReducer
