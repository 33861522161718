import React, { useState } from 'react'
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import ActionProvider from 'src/ActionProvider'
import config from 'src/config'
import MessageParser from 'src/MessageParser'
import CloseIcon from 'src/views/pages/login/icons/Close'

const ChatAI = () => {
  const [isChatOpen, setIsChatOpen] = useState(false)

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev)
  }

  return (
    <div>
      {/* Toggle Button */}
      {!isChatOpen && (
        <button
          onClick={toggleChat}
          className="fixed bottom-4 right-6 bg-[#014d99] text-white px-4 py-2 rounded-2xl shadow-lg hover:bg-[#01274d] transition duration-300 ease-in-out"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            fill="#fff"
            viewBox="0 0 256 256"
          >
            <path d="M168,112a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,112Zm-8,24H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm72-8A104,104,0,0,1,79.12,219.82L45.07,231.17a16,16,0,0,1-20.24-20.24l11.35-34.05A104,104,0,1,1,232,128Zm-16,0A88,88,0,1,0,51.81,172.06a8,8,0,0,1,.66,6.54L40,216,77.4,203.53a7.85,7.85,0,0,1,2.53-.42,8,8,0,0,1,4,1.08A88,88,0,0,0,216,128Z" />
          </svg>
        </button>
      )}

      {isChatOpen && (
        <div className="fixed bottom-4 right-4 w-[150px] lg:w-[300px] shadow-lg rounded-lg border bg-white">
          <div className="bg-[#014d99] text-white px-2 py-2 rounded-t-lg flex justify-between items-center">
            <h3 className="text-lg font-semibold">BK General Insurance</h3>
            <button
              className="rounded-full px-2 py-1 transition duration-300 ease-in-out"
              onClick={toggleChat}
            >
              <CloseIcon />
            </button>
          </div>

          <div className="p-4 w-full">
            <Chatbot
              config={{
                ...config,
                customComponents: {
                  ...config.customComponents,
                  header: () => null,
                },
              }}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatAI
