import { cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CBadge, CButton, CTooltip } from '@coreui/react'
import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { useNavigate } from 'react-router-dom'
import { showHidePreviewQuotation } from 'src/actions/displayAction'
import { COMESA_COVER_SELECTED, COVER_SELECTED } from 'src/actions/quotation/quotation-action'
import { initializeNida } from 'src/slices/nidaInfoSlice'
import { initializeProduct } from 'src/slices/productSlice'
import { initializeQuotion } from 'src/slices/quotationSlice'
import { initializeSystemUser } from 'src/slices/user_management_slice'
import Loading from 'src/components/Loanding'
// const dispatch = useDispatch() selecs wu
export function calculateDays(startDate, endDate) {
  try {
    const differenceMs = endDate.getTime() - startDate.getTime()
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24))
    return differenceDays + 1
  } catch (error) {
    return 1
  }
}
export const isRoleIncluded = (roles, str) => {
  return roles.some((role) => role.roleName === str)
}
export const calDays = (d1, d2) => {
  try {
    const differenceMs = convertStringToDate(d2).getTime() - convertStringToDate(d1).getTime()
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24))
    return differenceDays + 1
  } catch (error) {}
}
function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Month is zero-indexed, so add 1
  const day = String(date.getDate()).padStart(2, '0')
  let x = `${year}-${month}-${day}`

  return x
}
function convertStringToDate(dateString) {
  const [day, month, year] = dateString.split('/').map(Number)
  return new Date(year, month - 1, day) // Month is zero-indexed, so subtract 1
}

export const formatDate = (date) => {
  if (!date) {
    return ''
  }
  const dat = new Date(date)
  if (isNaN(dat)) {
    return ''
  }
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return `${day}/${month}/${year}`
}

const jsonData = (form) => {
  const formData = new FormData(form)
  const formDataJSON = {}

  formData.forEach((value, key) => {
    formDataJSON[key] = value
  })
  return formDataJSON
}
const coverToDisplay = (covers) => {
  return covers.map((cover) => ({ value: cover.coverid, label: cover.cover_NAME }))
}
export const coverToDisplayPA = (covers) => {
  return covers.map((cover) => ({ value: cover.covercode, label: cover.cover_NAME }))
}
export const isStep2Allowed = (v, s) => {
  if (v === 'MOTO' && s === 2) {
    return true
  } else if (v !== 'MOTO') {
    return true
  } else {
    return false
  }
}
const coverIdForPeriod = (covers, selectedProduct) => {
  try {
    if (selectedProduct.product_CODE === 'MOTO') {
      const motoCover = covers.find((cover) => cover.covercode === 'TPL')
      return motoCover ? motoCover.coverid : 0
    } else if (selectedProduct.product_CODE === 'PA') {
      const motoCover1 = covers.find((cover) => cover.covercode === 'DEATH')

      return motoCover1 ? motoCover1.coverid : 0
    } else {
      return covers[0].coverid
    }
  } catch (error) {
    return 0
  }
}
const motoRegionLimits = [
  { value: 'RWANDA', label: 'RWANDA' },
  { value: 'COMESA', label: 'COMESA' },
]

const isComesa = (limits) => {
  return limits.includes('COMESA')
}
export const docNoParam = (location) => {
  const queryParam = new URLSearchParams(location.search)
  return queryParam.get('docNo')
}
const clientTypes = [{ name: 'PRIVATE' }, { name: 'GOVERNMENT' }]

const premiumValue = (selectedProduct, coverItem, detailResponse) => {
  if (selectedProduct.product_CODE === 'MOTO') {
    switch (coverItem.covercode) {
      case 'TPL':
        return detailResponse?.tplPremium
      case 'OCCUPANT':
        return detailResponse?.occupantsPremium
      case 'SEATS':
        return detailResponse?.seatsPremium
      case 'MATERIAL_DAMAGE':
        return detailResponse?.materialDamagePremium
      case 'THEFT':
        return detailResponse?.theftPremium
      case 'FIRE':
        return detailResponse?.firePremium
      default:
        return 0
    }
  } else if (selectedProduct.product_CODE === 'FIRE_I') {
    if (detailResponse.selectedCovers.length === 1) {
      return detailResponse?.detailedPremium?.fireLightningExplosionPremium
    } else {
      return 0
    }
  } else {
    return 0
  }
}
// const objToString = (obj) => {
//   return Object.entries(obj).length === 0
//     ? obj
//     : Object.entries(obj)
//         .map(([key, value]) => value)
//         .join(',')
// }

const objToString = (obj) => {
  if (!obj) return ''
  return Object.entries(obj)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ')
}

export const vehicleCathegoris = (types) => {
  if (!Array.isArray(types)) {
    return []
  }

  const uniqueCat = [...new Set(types.map((t) => t.category))]
  return uniqueCat.map((c) => {
    return { name: c }
  })
}

export const businessLabels = [
  { name: 'businessName', label: 'Name of the business' },
  { name: 'tinNo', label: 'TIN Number' },
  { name: 'businessNature', label: 'Business Nature' },
  { name: 'businessLocation', label: 'Business Location' },
  { name: 'registrationDate', label: 'Registration Date' },
  { name: 'countryOrigin', label: 'Country of Origin' },
]

export const legalRepLabels = [
  { name: 'dob', label: 'Date of Birth' },
  { name: 'representativeName', label: 'Representative Name' },
  { name: 'email', label: 'Email' },
  { name: 'phoneNo', label: 'Phone No' },
  { name: 'residence', label: 'Residence' },
  { name: 'nationality', label: 'Nationality' },
  { name: 'position', label: 'Position' },
]
export const updateDetailsFromClientInfo = (clientInfo, setBusinessDetails, setLegalRepDetails) => {
  setBusinessDetails({
    businessName: clientInfo?.businessName || '',
    tinNo: clientInfo?.tinNo || '',
    businessNature: clientInfo?.businessNature || '',
    businessLocation: clientInfo?.businessLocation || '',
    registrationDate: clientInfo?.registrationDate || '',
    countryOrigin: clientInfo?.countryOrigin || '',
  })

  setLegalRepDetails({
    representativeName: clientInfo?.representativeName || '',
    email: clientInfo?.email || '',
    phoneNo: clientInfo?.phoneNo || '',
    residence: clientInfo?.residence || '',
    nationality: clientInfo?.nationality || '',
    position: clientInfo?.position || '',
    dob: clientInfo?.dob || '',
  })
}

export const shareholderInfoLabel = [
  { name: 'shareHolderType', label: 'Type' },
  { name: 'shareHolderId', label: 'National Id / Tin Number' },
  { name: 'shareHolderName', label: 'Names' },
  { name: 'natureOfBusiness', label: 'Business Nature' },
  { name: 'shares', label: 'Shares In %' },
  { name: 'phoneNumber', label: 'Phone Number' },
  { name: 'residence', label: 'Residence' },
]
export const uobForm = [
  { name: 'tinNo', label: 'Company' },
  { name: 'nationalId', label: 'National Id' },
  { name: 'repFname', label: ' FirstName' },
  { name: 'repLname', label: ' Last Name' },
  { name: 'residential', label: 'Residential Address' },
  { name: 'email', label: 'Email' },
  { name: 'nationality', label: 'Nationality' },
  { name: 'position', label: ' Position' },
]
export const updateDetailsFromShareHoldersInfo = (
  shareHolder,
  setCompanyDetails,
  setIndividualDetails,
) => {
  setCompanyDetails({
    companyTinNumber: shareHolder?.companyTinNumber || '',
    companyName: shareHolder?.companyName || '',
    companyNatureOfBusiness: shareHolder?.companyNatureOfBusiness || '',
    companyShare: shareHolder?.companyShare || '',
    companyPhoneNumber: shareHolder?.companyPhoneNumber || '',
    companyResidence: shareHolder?.companyResidence || '',
  })

  setIndividualDetails({
    id: shareHolder?.id || '',
    name: shareHolder?.name || '',
    natureOfBusiness: shareHolder?.natureOfBusiness || '',
    shares: shareHolder?.shares || '',
    phoneNumber: shareHolder?.phoneNumber || '',
    residence: shareHolder?.residence || '',
  })
}

export const calculatorStep = [{ label: 'VEHICLE DETAILS' }, { label: 'INSURANCE DETAILS' }]
export const intiatePaegable = { page: 0, size: 40 }
export const quotationReportColumn = () => {
  return [
    {
      name: 'Quotation No',
      selector: (r) => r.quotationCode,
    },
    {
      name: 'Product',
      selector: (r) => r.productCode,
    },
    { name: 'Net Premium', selector: (r) => r.netPremium },
    { name: 'Vat', selector: (r) => r.vatFee },
    { name: 'Comesa Fee', selector: (r) => r.comesaMedicalFee },
    { name: 'Admin Fee', selector: (r) => r.adminFee },
    { name: 'Taxable Premium', selector: (r) => r.taxablePremium },
    { name: 'SGF', selector: (r) => r.sgfFee },
    { name: 'Total Premium', selector: (r) => r.totalChargeablePremium },
    { name: 'Tpl Premium', selector: (r) => r.tplPremium },
    { name: 'Occupants Premium', selector: (r) => r.occupantsPremium },
    { name: 'Seats Premium', selector: (r) => r.seatsPremium },
    { name: 'Own Damage Premium', selector: (r) => r.materialDamagePremium },
    { name: 'Theft Premium', selector: (r) => r.theftPremium },
    { name: 'Fire Premium', selector: (r) => r.firePremium },
    { name: 'OTF Premium', selector: (r) => r.otf },

    {
      name: 'Fire and LightningExplosion Premium',
      selector: (r) => r.fireLightningExplosionPremium,
    },
    { name: 'Earthquake Premium', selector: (r) => r.earthquakePremium },
    {
      name: 'Storm Tempest Flood, Tornado Premium',
      selector: (r) => r.stormTempestFloodTornadoPremium,
    },
    { name: 'Riot Strike Premium', selector: (r) => r.riotStrikePremium },
    { name: 'Malicious Damage Premium', selector: (r) => r.maliciousDamagePremium },
    { name: 'Burstm Pipe Premium', selector: (r) => r.burstPipePremium },
    { name: 'Impact Premium', selector: (r) => r.impactPremium },
    { name: 'Bush Fire Premium', selector: (r) => r.bushFirePremium },
    { name: 'Subsidence Premium', selector: (r) => r.subsidencePremium },
    { name: 'Spontaneous Combustion Premium', selector: (r) => r.spontaneousCombustionPremium },
    { name: 'Start Date', selector: (r) => r.startDate },
    { name: 'End Date', selector: (r) => r.endDate },
    { name: 'Year Of Make', selector: (r) => r.yearOfMake },
    { name: 'Plate No', selector: (r) => r.plateNo },
    { name: 'Death Premium', selector: (r) => r.deathPremium },
    { name: 'Tpd Premium', selector: (r) => r.tpdPremium },
    { name: 'Ttd Premium', selector: (r) => r.ttdPremium },
    { name: 'Medical Ex. Premium', selector: (r) => r.medicalExPremium },
    { name: 'Funeral Ex. Premium', selector: (r) => r.funeralExPremium },
    { name: 'National Id', selector: (r) => r.nationalId },
    { name: 'Customer Phone', selector: (r) => r.customer?.msisdn },
    { name: 'Creation Date', selector: (r) => r.createdDate },
  ]
}
export const ebmItemColumn = () => {
  return [
    {
      name: 'Item Id',
      selector: (row) => row.ITEM_ID,
    },
    {
      name: 'Item Description',
      selector: (row) => row.ITEM_DESCRIPTION,
    },
    {
      name: 'Amount',
      selector: (row) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'FRW',
        }).format(row.AMOUNT)
      },
    },
    {
      name: 'Quantity',
      selector: (row) => row.QTY,
    },
    {
      name: 'VAT RATE',
      selector: (row) => `${row.VAT_RATE * 100}%`,
    },
  ]
}
export const uobShareHolderColumn = () => {
  const colmns = [
    {
      name: 'ShareHolder Name',
      selector: (row) => row.shareHolderName,
    },
    {
      name: 'Bussines Nature',
      selector: (row) => row.natureOfBusiness,
    },
    {
      name: 'ShareHolder Type',
      selector: (row) => row.shareHolderType,
    },
    {
      name: 'Shares',
      selector: (row) => `${row.shares}%`,
    },
    {
      name: 'TIN',
      selector: (row) => row.shareHolderId,
    },
    {
      name: 'Phone',
      selector: (row) => row.phoneNumber,
    },
  ]

  colmns.push({
    name: 'Beneficial Names',
    selector: (row) => `${row.uob?.repFname || 'N/A'} ${row.uob?.repLname || ''}`,
  })
  colmns.push({
    name: 'nationalId',
    selector: (row) => `${row.uob?.nationalId || 'N/A'}`,
  })
  colmns.push({
    name: 'Position',
    selector: (row) => `${row.uob?.position || 'N/A'}`,
  })
  colmns.push({
    name: 'Email',
    selector: (row) => `${row.uob?.email || 'N/A'}`,
  })
  colmns.push({
    name: 'Residence',
    selector: (row) => `${row.uob?.residential || 'N/A'}`,
  })
  colmns.push({
    name: 'Nationality',
    selector: (row) => `${row.uob?.nationality || 'N/A'}`,
  })

  return colmns
}
export const exportToExcelBeneficialCompany = (data) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const fileName = 'BeneficialOwner_' + new Date() + '_data_export'
  // Prepare data for export
  const flattenedData = data.flatMap((company) =>
    company.shareHolders.map((shareHolder) => ({
      businessName: company.businessName,
      businessNature: company.businessNature,
      businessLocation: company.businessLocation,
      companyOrigin: company.countryOrigin,
      registrationDate: company.registrationDate,
      companyTinNo: company.tinNo,
      representativeName: company.representativeName,
      position: company.position,
      dob: company.dob,
      phoneNo: company.phoneNo,
      email: company.email,
      residence: company.residence,
      shareHolderName: shareHolder.shareHolderName,
      natureOfBusiness: shareHolder.natureOfBusiness,
      shareHolderType: shareHolder.shareHolderType,
      shares: shareHolder.shares,
      tinNo: shareHolder.shareHolderId,
      phoneNumber: shareHolder.phoneNumber,
      countryOrigin: shareHolder.countryOrigin,
    })),
  )
  // Create a workbook
  const ws = XLSX.utils.json_to_sheet(flattenedData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Beneficial Company Data')
  // Convert workbook to Excel file and save
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const dataFile = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(dataFile, fileName + fileExtension)
}
export const exportQuotationRegister = (data) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const fileName = 'QuotationRegisterReportOf' + new Date() + '_data_export'
  // Prepare data for export
  const flattenedData = data.map((quot) => ({
    QuotationCode: quot.quotationCode,
    Product: quot.productCode,
    NetPremium: quot.netPremium,
    Vat: quot.vatFee,
    ComesaMedicalFee: quot.comesaMedicalFee,
    PolicyFee: quot.adminFee,
    TaxablePremium: quot.taxablePremium,
    SgfFee: quot.sgfFee,
    TotalChargeablePremium: quot.totalChargeablePremium,
    TplPremium: quot.tplPremium,
    OccupantsPremium: quot.occupantsPremium,
    SeatsPremium: quot.seatsPremium,
    MaterialDamagePremium: quot.materialDamagePremium,
    TheftPremium: quot.theftPremium,
    FirePremium: quot.firePremium,
    OtfPremium: quot.otf,
    FireLightningExplosionPremium: quot.fireLightningExplosionPremium,
    EarthquakePremium: quot.earthquakePremium,
    StormTempestFloodTornadoPremium: quot.stormTempestFloodTornadoPremium,
    // Add more fields from shareHolders as needed
    RiotStrikePremium: quot.riotStrikePremium,
    MaliciousDamagePremium: quot.maliciousDamagePremium,
    BurstPipePremium: quot.burstPipePremium,
    ImpactPremium: quot.impactPremium,
    BushFirePremium: quot.bushFirePremium,
    SubsidencePremium: quot.subsidencePremium,
    SpontaneousCombustionPremium: quot.spontaneousCombustionPremium,
    StartDate: quot.startDate,
    EndDate: quot.endDate,
    YearOfMake: quot.yearOfMake,
    //
    PlateNo: quot.plateNo,
    DeathPremium: quot.deathPremium,
    TpdPremium: quot.tpdPremium,
    TtdPremium: quot.ttdPremium,
    MedicalExPremium: quot.medicalExPremium,
    FuneralExPremium: quot.funeralExPremium,
    NationalId: quot.nationalId,
    customerPhone: quot.msisdn,
    CreatedDate: quot.createdDate,
  }))
  // Create a workbook
  const ws = XLSX.utils.json_to_sheet(flattenedData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Quotation Register Report')
  // Convert workbook to Excel file and save
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const dataFile = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(dataFile, fileName + fileExtension)
}
export const uobRegisterReportColumn = () => {
  return [
    {
      name: 'Company Name',
      selector: (row) => row.businessName,
      sortable: true,
    },
    {
      name: 'Bussines Nature',
      selector: (row) => row.businessNature,
      sortable: true,
    },
    {
      name: 'Location',
      selector: (row) => row.businessLocation,
      sortable: true,
    },
    {
      name: 'Country',
      selector: (row) => row.countryOrigin,
      sortable: true,
    },
    {
      name: 'Registration Date',
      selector: (row) => row.registrationDate,
      sortable: true,
    },
    {
      name: 'Company TIN',
      selector: (row) => row.tinNo,
      sortable: true,
    },
    {
      name: 'Representative',
      selector: (row) => row.representativeName,
      sortable: true,
    },
    {
      name: 'Position',
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: 'Date of Birth',
      selector: (row) => row.dob,
      sortable: true,
    },
    {
      name: 'Telephone',
      selector: (row) => row.phoneNo,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Residence',
      selector: (row) => row.residence,
      sortable: true,
    },
  ]
}

export const SystemUsersColumn = ({ setSelectedUser }) => {
  const navigate = useNavigate()

  const handleEdit = (row) => {
    setSelectedUser(row)
    navigate('/profile?edit=true', { state: { user: row } })
  }

  return [
    {
      name: 'First Name',
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: 'UserName',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className="space-x-2 text-white mt-1">
          <button className="bg-[#014D99] p-2" type="button" onClick={() => handleEdit(row)}>
            Edit
          </button>
          <button className="bg-[#D70909] p-2" type="button">
            Delete
          </button>
        </div>
      ),
      sortable: false,
    },
  ]
}
export const totalsSdcInvoice = (items) => {
  const totals = items.reduce(
    (acc, item) => {
      if (item.ITEM_ID === '20-SGF') {
        acc.totalA = item.AMOUNT
      } else {
        acc.totalB += item.AMOUNT
        acc.totalTaxB += item.AMOUNT * item.VAT_RATE
      }
      acc.totalAmount += item.AMOUNT
      return acc
    },
    {
      totalA: 0,
      totalB: 0,
      totalTaxB: 0,
      totalTax: 0,
      totalAmount: 0,
      itemsNumber: items.length,
      mrc: 'BKGEN000001',
    },
  )

  totals.totalTax = totals.totalTaxB
  totals.totalAmount += totals.totalTax
  return totals
}
export const sdcInformationB = (totals) => {
  return [
    { label: 'TOTAL A-EX-0.0%', value: totals.totalA.toLocaleString() },
    { label: 'TOTAL B-18.0%', value: totals.totalB.toLocaleString() },
    { label: 'TOTAL TAX B', value: totals.totalTaxB.toLocaleString() },
    { label: 'TOTAL TAX', value: totals.totalTax.toLocaleString() },
    { label: 'TOTAL AMOUNT', value: `${totals.totalAmount.toLocaleString()} RWF` },
    { label: 'ITEMS NUMBER', value: totals.itemsNumber },
    { label: 'MRC', value: totals.mrc },
  ]
}
export const sdcInformationA = (sdcInfo) => {
  const [date, time] = sdcInfo.key_4.split(' ')
  const receipt = `${sdcInfo.key_1}/${sdcInfo.key_2} ${sdcInfo.key_3}`
  return [
    { label: 'SDC ID', value: sdcInfo.key_0 },
    { label: 'DATE', value: date },
    { label: 'TIME', value: time },
    { label: 'RECEIPT NUMBER', value: receipt },
    { label: 'Internal Data', value: sdcInfo.key_6 },
    { label: 'Signature', value: sdcInfo.key_5 },
    { label: 'CIS DATE', value: sdcInfo.key_4 },
  ]
}
export const sdcDataFormat = (input) => {
  const items = input.split(',')
  return items.reduce((acc, item, index) => {
    acc[`key_${index}`] = item
    return acc
  }, {})
}
export const ebmColumn = (setJpa, setShowModal, globalState, modalFunc) => {
  const { loading } = globalState
  return [
    {
      name: 'Resend',
      selector: (row) => (
        <CTooltip content="Repush Ebm">
          <CButton
            className="btn-sm"
            disabled={row.status == 'ERROR' && row.isEbmAllowed == 'YES' ? false : true}
            onClick={() => {
              try {
                modalFunc(row)
              } catch (e) {}
            }}
          >
            Re-Push
          </CButton>
        </CTooltip>
      ),
    },
    {
      name: 'Status',
      selector: (row) => (
        <CBadge color={row.status == 'SUCCESS' ? 'success' : 'danger'} className="px-2 py-1">
          {row.status ? row.status : 'N/A'}
        </CBadge>
      ),
    },
    {
      name: 'Amount',
      selector: (row) => {
        const formattedAmount = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'FRW',
        }).format(row.premiumAmount)

        return formattedAmount
      },
    },
    {
      name: 'TransId',
      selector: (row) => row.transId,
    },
    {
      name: 'TransRefer',
      selector: (row) => row.referenceCrDbP,
    },
    {
      name: 'DocNo',
      selector: (row) => row.DOC_ID,
    },
    {
      name: 'BaseDocNo',
      selector: (row) => row.BASE_DOC_ID,
    },
    {
      name: 'EBM_DocIdRefer',
      selector: (row) => row.ebmDocIdReference,
    },
    {
      name: 'DocType',
      selector: (row) => row.DOC_TYPE,
    },
    {
      name: 'TransType',
      selector: (row) => row.transType,
    },
    { name: 'PolicyNo', selector: (row) => row.POLICY_NO },
    { name: 'Product', selector: (row) => row.PRODUCT },
    { name: 'TinNo', selector: (row) => row.CUSTOMER_TIN },
    { name: 'ClientName', selector: (row) => row.CUSTOMER_NAME },
    { name: 'Phone', selector: (row) => row.MOBILE_NO },
    { name: 'Source', selector: (row) => row.source },
    {
      name: 'SdCData',
      selector: (row) => row.sdcData,
    },
    { name: 'DocDesc', selector: (row) => row.DOC_DESC },
    { name: 'CreatedBy', selector: (row) => row.CREATED_BY },
    { name: 'CreatedBy', selector: (row) => row.APPROVAL_DATE },
    {
      name: 'Allowed',
      selector: (row) => (
        <CBadge color={row.isEbmAllowed == 'YES' ? 'info' : 'warning'}>{row.isEbmAllowed}</CBadge>
      ),
    },
    {
      name: 'View Posted',
      selector: (row) => (
        <CTooltip content="View Invoice Item Sent to Odo">
          <CButton
            className="btn-sm btn-info"
            disabled={row.isEbmAllowed == 'YES' ? false : true}
            onClick={() => {
              setJpa({
                message: row?.message,
                id: row.transId,
                DOC_ID: row.DOC_ID,
                post: row.ebmPostData,
              })
              setShowModal(true)
            }}
          >
            View
          </CButton>
        </CTooltip>
      ),
    },
  ]
}
export const usersColumn = (dispatch) => {
  return [
    {
      name: 'Reject/Approve',
      cell: (row) => (
        <CButton className="btn-sm" tooltip="Edit user">
          <CIcon icon={cilPencil} tooltip="Edit user" />
        </CButton>
      ),
    },
    {
      name: 'USERNAME',
      selector: (row) => row.username,
    },

    {
      name: 'FIRST NAME',
      selector: (row) => row.first_NAME,
    },
    {
      name: 'LAST NAME',
      selector: (row) => row.last_NAME,
    },
    {
      name: 'EMAIL',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'PHONE',
      selector: (row) => row.phone,
    },
  ]
}
const tableHeaderStyle = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '14px',
      backgroundColor: '#000000',
      color: '#ffffff',
    },
  },
}

const userHeaderStyle = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '14px',
      backgroundColor: '#014d99',
      color: '#ffffff',
    },
  },
}
export const tableHeaderExStyle = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: '12px',
      backgroundColor: '#333',
      color: '#ffffff',
    },
  },
}
export const coverToBeSent = (selectedList, covers) => {
  try {
    const item1 = Object.entries(selectedList)
      .filter(([k, v]) => v === true)
      .map(([k, v]) => k)
    return covers.filter((cover) => item1.includes(cover.covercode)).map((item) => item.coverid)
  } catch (error) {
    return []
  }
}
export const shareHolderCompany = (data) => {
  return data.filter(
    (shareholder) => shareholder.shareHolderType && shareholder.shareHolderType.includes('COMPANY'),
  )
}
export const setDefaultTeritorios = (setSelectedLimits, comesa, selectedLimits) => {
  setSelectedLimits(['RWANDA'])
  if (comesa) {
    if (selectedLimits.filter((x) => x == 'COMESA').length > 0) {
      setSelectedLimits(['RWANDA'])
    } else {
      setSelectedLimits(['RWANDA', 'COMESA'])
    }
  } else {
    setSelectedLimits(['RWANDA'])
  }
}
export const seatsPremiumX = (vehicleId, vehicleList) => {
  const x = vehicleList.find((x) => x.motoTypeId == vehicleId)
  return x.seatsFee
}

export const isUobRequired = (shareHolder) => {
  return shareHolder.some((holder) => holder.shareHolderType === 'COMPANY')
}
const stateReset = (dispatch) => {
  dispatch(initializeNida())
  dispatch(initializeSystemUser())
  dispatch(showHidePreviewQuotation(false))
  dispatch(initializeQuotion())
  dispatch(initializeProduct())
  dispatch(COVER_SELECTED({}))
  dispatch(COMESA_COVER_SELECTED({}))
}
const convertValueToName = (str) => {
  var transformedStr = str.replace(/([A-Z])/g, ' $1').trim()
  return transformedStr.charAt(0).toUpperCase() + transformedStr.slice(1)
}
const fireObjToArry = (obj) => {
  var jsonArray = []
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      var item = {}
      item[key] = obj[key]
      jsonArray.push(item)
    }
  }
  return jsonArray
}
export const sortProducts = (products) => {
  let flatArray = products.flat()
  // Step 2: Sort the array so that 'MOTO' appears first
  flatArray.sort((a, b) => {
    if (a.product_CODE === 'MOTO') return -1 // Move 'MOTO' to the front
    if (b.product_CODE === 'MOTO') return 1
    if (a.product_CODE === 'FIRE_I') return -1
    if (b.product_CODE === 'FIRE_I') return 1
    return 0 // Keep the rest in original order
  })
  return flatArray
}
export const groupProduct = (products) => {
  let productsx =
    products /* products.filter((x) => x.product_CODE == 'MOTO' || x.product_CODE == 'FIRE_I')*/
  const groupedProducts = []
  for (let i = 0; i < productsx.length; i += 2) {
    groupedProducts.push(productsx.slice(i, i + 2))
  }
  return groupedProducts
}
export const periodPlan = [
  { name: '3/9', days: '90/270' },
  { name: '1/2/9', days: '30/60/270' },
  { name: '6/6', days: '180/180' },
]
export const periodType = [
  { name: 'Annualy', value: 'Annualy' },
  { name: 'Installement', value: 'planCoverPeriod' },
  { name: 'Short Term', value: 'shortCoverPeriod' },
]
export const shortPeriodPlan = [
  { name: '1 Day(s)', days: '1' },
  { name: '2 Day(s)', days: '2' },
  { name: '3 Day(s)', days: '3' },
  { name: '4-8 Day(s)', days: '8' },
  { name: '9-15 Day(s)', days: '15' },
  { name: '16-1 Month', days: '30' },
  { name: '1,1-2 Month', days: '60' },
  { name: '2,2-3 Months', days: '90' },
  { name: '3,1-4 Months', days: '120' },
  { name: '4,1-5 Months', days: '150' },
  { name: '5,1-6 Months', days: '180' },
  { name: '6,1-7 Months', days: '213' },
  { name: '7,1-12 Month (1 Year)', days: '365' },
]
export {
  fireObjToArry,
  jsonData,
  convertValueToName,
  stateReset,
  coverToDisplay,
  motoRegionLimits,
  clientTypes,
  tableHeaderStyle,
  userHeaderStyle,
  coverIdForPeriod,
  isComesa,
  objToString,
  premiumValue,
}
export const fireCovers = [
  {
    coverCode: 'FIRE_STANDARD',
    category: 'FIRE_STANDARD',
    coverName: 'Fire,Lightning & Explosion',
    coverDetails: 'Fire,Lightning & Explosion',
  },
  {
    coverCode: '22_EART',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Earthquake',
    coverDetails: 'Earthquake',
  },
  {
    coverCode: '23_STOR',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Storm, Tempest, Flood & Tornado',
    coverDetails: 'Storm, Tempest, Flood & Tornado',
  },
  {
    coverCode: '24_RIOT',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Riot & Strike',
    coverDetails: 'Riot & Strike',
  },
  {
    coverCode: '25_MALI',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Malicious damage',
    coverDetails: 'Malicious damage',
  },
  {
    coverCode: '26_BURS',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Burst Pipe',
    coverDetails: 'Burst Pipe',
  },
  {
    coverCode: '27_IMPA',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Impact',
    coverDetails: 'Impact',
  },
  {
    coverCode: '28_BUSH',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Bush Fire',
    coverDetails: 'Bush Fire',
  },
  {
    coverCode: '29_SUBS',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Subsidence',
    coverDetails: 'Subsidence',
  },
  {
    coverCode: '30_SPON',
    category: 'FIRE_STANDARD_PERILS',
    coverName: 'Spontaneous Combustion',
    coverDetails: 'Spontaneous Combustion',
  },
  {
    coverCode: '36_FULL_FIRE_PERILS',
    category: 'FIRE_STANDARD_FULL_PERILS',
    coverName: 'FIRE STANDARD AND FULL ALLIED PERILS',
    coverDetails:
      'Fire,Lightning & Explosion,Earthquake,Storm, Tempest, Flood & Tornado,Riot & Strike,Malicious damage,Burst Pipe,Impact,Bush Fire,Subsidence,Spontaneous Combustion',
  },
]
export const socialEconomicClassList = [
  { value: '1', label: 'Ubudehe 1' },
  { value: '2', label: 'Ubudehe 2' },
  { value: '3', label: 'Ubudehe 3' },
  { value: '4', label: 'Ubudehe 4' },
]
export const education = [
  {
    Education: '1',
    Education_Description: 'PHD',
  },
  {
    Education: '2',
    Education_Description: 'Masters',
  },
  {
    Education: '3',
    Education_Description: 'Bachelors Degree',
  },
  {
    Education: '4',
    Education_Description: 'Diploma (A2 or A1 level)',
  },
  {
    Education: '5',
    Education_Description: 'School attendace below A2 level',
  },
  {
    Education: '6',
    Education_Description: 'High School',
  },
  {
    Education: '7',
    Education_Description: 'Primary School',
  },
  {
    Education: '8',
    Education_Description: 'Below Primary',
  },
]
export const occupation = [
  {
    Occupation: 'M0',
    Occupation_Description: 'Armed forces occupations',
  },
  {
    Occupation: 'M1',
    Occupation_Description: 'Managers',
  },
  {
    Occupation: 'M2',
    Occupation_Description: 'Professionals',
  },
  {
    Occupation: 'M3',
    Occupation_Description: 'Technicians and associate professionals',
  },
  {
    Occupation: 'M4',
    Occupation_Description: 'Clerical support workers',
  },
  {
    Occupation: 'M5',
    Occupation_Description: 'Service and sales workers',
  },
  {
    Occupation: 'M6',
    Occupation_Description: 'Skilled agricultural, forestry and fishery workers',
  },
  {
    Occupation: 'M7',
    Occupation_Description: 'Craft and related trades workers',
  },
  {
    Occupation: 'M8',
    Occupation_Description: 'Plant and machine operators, and assemblers',
  },
  {
    Occupation: 'M9',
    Occupation_Description: 'Elementary occupations',
  },
  {
    Occupation: 'MX',
    Occupation_Description: 'Others in General',
  },
]
export const aiForSumInsuredOnFire = (code, amount) => {
  return code == 'FIRE_STANDARD' || code == '36_FULL_FIRE_PERILS' || code == '22_EART'
    ? amount
    : (amount * 10) / 100
}
export const patterns = {
  motorVehicle: /^R[A-Z]{2}[0-9]{3}[A-Z]$/, // RBC001T
  motorcycle: /^R[A-Z][0-9]{3}[A-Z]$/, // RC123P
  temporaryNGOs: /^IT[0-9]{3}R[A-Z]$/, // IT110RE
  government: /^(GR|GP)[0-9]{3}[A-Z]$/, // GR012K, GP912C
  nationalPolice: /^RNP[0-9]{3}[A-Z]$/, // RNP025K
  defenseForce: /^RDF[0-9]{3}[A-Z]$/, // RDF896M
  diplomatic: /^[0-9]{1,2}C(MD|D)[0-9]{2}[A-Z]$/, // 99CMD01R, 9CD01R
  unAgencies: /^UN[0-9]{2}[A-Z]$/, // UN55P
}
/*export const termsDm =
  'By clicking this checkbox, I confirm that I have reviewed and read the contract in its entirety, understand its terms and conditions, and agree to accept and sign the contract electronically.'
  */
export const termsDm =
  'By checking this box, I confirm that I have read and understood the entire contract and agree to sign it electronically.'
export const totalPremium = (data) => {
  return data.reduce(
    (totals, item) => {
      totals.adminFee += item?.data ? item.data.adminFee : item?.adminFee || 0
      totals.netPremium += item?.data ? item.data.netPremium : item?.netPremium || 0
      totals.comesaPremium += item?.data ? item.data.comesaPremium : item?.comesaPremium || 0
      totals.vatFee += item?.data ? item.data.vatFee : item?.vatFee || 0
      totals.sgfFee += item?.data ? item.data.sgfFee : item?.sgfFee || 0
      totals.tplPremium += item?.data ? item.data.tplPremium : item?.tplPremium || 0
      totals.occupantsPremium += item?.data
        ? item.data.occupantsPremium
        : item?.occupantsPremium || 0
      totals.totalChargeablePremium += item?.data
        ? item.data.totalChargeablePremium
        : item?.totalChargeablePremium || 0
      return totals
    },
    {
      adminFee: 0,
      netPremium: 0,
      comesaPremium: 0,
      vatFee: 0,
      sgfFee: 0,
      totalChargeablePremium: 0,
      occupantsPremium: 0,
      tplPremium: 0,
    },
  )
}

export const currencyUssd = (amount) => {
  // Check if the amount is a valid number
  if (isNaN(amount) || amount == null) {
    return '0 frw' // Return '0 frw' for invalid numbers
  }

  const formattedAmount = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(amount)

  return `${formattedAmount} frw` // Capitalized currency at the end
}

export const motoDataRequest = (
  phone,
  occupation,
  fatherName,
  spouseName,
  education,
  nationalId,
  plateNo,
  yearManufacture,
  chassisNo,
  vehicleCat,
  vehicleMode,
  engineNo,
  vehicleTypeId,
  noOfSeats,
  sumInsured,
  startDate,
  endDate,
  comesa = false,
  covers,
  comesaCovers = [],
  cmedical = false,
  clientType = 'PRIVATE',
  sumInsuredPerOccupants,
  numberOfOccupants,
) => {
  const customerInfo = {
    phone: phone,
    nationalId: nationalId,
    occupation: occupation,
    fatherName: fatherName,
    spouseName: spouseName,
    education: education,
  }
  const vehicleDetails = {
    plateNo: plateNo,
    manufacturingYear: yearManufacture,
    chassisNo: chassisNo,
    vehicleCategory: vehicleCat,
    vehicleModal: vehicleMode,
    engineNo: engineNo,
    vehicleTypeId: vehicleTypeId,
    noOfSeats: noOfSeats,
    valueOfVehicle: sumInsured,
  }
  const policeDetails = {
    startDate: formatDate(startDate),
    expiryDate: formatDate(endDate),
    productCode: 'MOTO',
    comesa: comesa,
    covers: covers,
    comesaCovers: comesaCovers,
    cmedical: cmedical,
    clientType: clientType,
    sumInsuredPerOccupants: sumInsuredPerOccupants,
    numberOfOccupants: numberOfOccupants,
  }
  return { policeDetails, customerInfo, vehicleDetails, source: 'WEB_PORTAL' }
}
